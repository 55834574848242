import styles from "./BrickLayout.module.css"

const BrickLayout = () => {
  return (
    <div className={styles["brick-layout"]}>
      <div className={styles["brick-column"]}>
        <img src="img/art/img1.jpg" alt="" />
        <img src="img/art/img2.jpg" alt="" />
        <img src="img/art/img3.jpg" alt="" />
      </div>
      <div className={styles["brick-column"]}>
        <img src="img/art/img4.jpg" alt="" />
        <img src="img/art/img5.jpg" alt="" />
        <img src="img/art/img6.jpg" alt="" />
      </div>
      <div className={styles["brick-column"]}>
        <img src="img/art/img7.jpg" alt="" />
        <img src="img/art/img8.jpg" alt="" />
        <img src="img/art/img1.jpg" alt="" />
      </div>
    </div>
  )
}

export default BrickLayout